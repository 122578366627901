export default {
    clothes: '\ue606',
    code: '\ue79d',
    fitness: '\ue65d',
    food: '\ue656',
    game: '\ue605',
    gift: '\ue604',
    image: '\ue796',
    landscape: '\ue63d',
    lodging: '\ue600',
    medical: '\ue8ad',
    mobile: '\ue63e',
    movie: '\ue603',
    rmb: '\ue61d',
    server: '\ue60f',
    snacks: '\ue60e',
    traffic: '\ue602',
    unknown: '\ue7a1'
}
